import request from '@/utils/request'

// 文件查询  经销商
export function pageFile(query) {
    return request({
        url: '/file/pageFile',
        method: 'get',
        params: query
    })
}

// 文件下载  经销商
export function downloadFileUrl(query) {
    return request({
        url: '/file/downloadFileUrl',
        method: 'get',
        params: query
    })
}
